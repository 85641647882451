<template>
  <div class="container mx-auto">
      <div class="row">
        <div class="col">
          入力した内容：{{ props.inputData }}
        </div>
      </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

var props = defineProps({
  inputData: { type: String, default: '' }
});
</script>