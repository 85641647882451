<template>
  <div class="container">
    <div class="row mx-auto">
      <button type="button" class="btn btn-outline-primary" @click="toNextPage">
        <i>`test`</i> というクエリパラメータを指定して<br/>次ページへ移動する
      </button>
      
      <hr class="gy-4"/>
    </div>
    <div class="row mx-auto form-floating">
      <input type="text" 
        id="textBindingElement"
        class="form-control"
        v-model="inputData"
      />
      <label for="textBindingElement">子コンポーネントへ渡す入力エリア</label>
    </div>
    <div class="row mx-auto">
      <div>↓↓</div>
      <RouterForm :inputData="inputData" />
      <hr class="gy-4"/>
    </div>

    <!-- Vuexでデータをやり取り -->
    <div class="row mx-auto form-floating">
      <input type="text" 
        label="saveDataToVuexElement"
        class="form-control"
        v-model="message"
      />
      <label for="saveDataToVuexElement">画面遷移時vuexへ保存される入力エリア</label>
    </div>
    <div class="row mx-auto">
      <button type="button" class="btn btn-outline-primary mt-4" @click="saveAndNextPageByVuex">
        入力内容をvuexへ保存して<br/>次ページへ移動する
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import RouterForm from './RouterForm.vue';

var store = useStore();
var route = useRoute();
var router = useRouter();

var inputData = ref('');
if(route.params.inputData) {
  inputData.value = route.params.inputData;
}
var message = ref(store.getters.getMessage);

function toNextPage() {
  router.push({ name: 'nextPage', query: { q: 'test' } });
}

function saveAndNextPageByVuex() {
  store.commit('setMessage', message.value);
  router.push({ name: 'routerFormVuex' });
}
</script>

<style scoped>
hr {
  position: relative;
  overflow: visible;
  text-align: center;
  border: double 2px black;
}
hr::after {
  position: absolute;
  display: inline-block;
  content: '§';
  top: -0.9em;
  width: 18px;
  font-weight: bold;
  background: #fff;
}
</style>
