<template>
  <div class="container">
    <div class="row mx-auto">
      <button type="button" class="btn btn-outline-primary" @click="getParam">
        URLからクエリパラメータを取得
      </button>
      <div class="mb-4">
        URLのパラメータ: {{ param }}
      </div>
      
      <button type="button" class="btn btn-outline-primary" @click="back">
        戻る
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
var route = useRoute();
var router = useRouter();

var param = ref('');
function getParam() {
  param.value = route.query.q;
}

function back() {
  router.push({ name: 'home' });
}
</script>