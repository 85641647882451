<template>
  <div class="container">
      <div class="row mx-auto">
        <div>Vuexから取得した内容：{{ message }}</div>
        <button type="button" class="btn btn-outline-primary mt-4" @click="back">
          戻る
        </button>
      </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
var store = useStore();
var router = useRouter();

var message = store.getters.getMessage;

function back() {
  router.push({ name: 'home' });
}
</script>