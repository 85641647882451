import vuex from 'vuex';

const store = new vuex.Store({
    state: {
        message: ''
    },
    getters: {
        getMessage(state) {
            return state.message;
        }
    },
    mutations: {
        setMessage(state, newMessage) {
            state.message = newMessage;
        }
    },
    actions: {
    },
    modules: {
    }
});

export default store;