import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NextPage from '../components/NextPage.vue'
import RouterFormVuex from '../components/RouterFormVuex.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/nextPage',
    name: 'nextPage',
    component: NextPage
  },
  {
    path: '/routerFormVuex',
    name: 'routerFormVuex',
    component: RouterFormVuex
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
